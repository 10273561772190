<template>
  <v-container
    id="register"
    fluid
    tag="section"
    class="pt-0 mt-0"
  >
    <v-row
      class="d-flex justify-center"
    >
      <v-col
        cols="12"
        sm="10"
        md="9"
        lg="8"
        xl="6"
        class="px-1 d-flex justify-center"
      >
        <v-card
          class="d-inline-flex"
          elevation="1"
          light
          width="100%"
        >
          <v-row
            no-gutters
            align="center"
            style="background: #00A1B7;"
          >
            <v-col
              cols="12"
              md="6"
            >
              <v-row
                no-gutters
                class="h-100"
              >
                <v-col class="h-100">
                  <v-img
                    src="/img/appbar-logo.png"
                    class="mx-auto"
                    width="220"
                    height="62"
                  ></v-img>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="h-md-50-vw pa-10"
              style="background: #fff;"
            >
              <v-row
                class="d-flex justify-center"
              >
                <v-col class=" title-text mb-1">
                  {{ $t('forgotPassWordPage.title') }}
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-form
                    ref="form"
                    @submit.prevent="submit"
                  >
                    <validation-observer
                      ref="observer"
                      v-slot="{ invalid }"
                    >
                      <v-row class="d-flex justify-center">
                        <v-col
                          class="pb-0"
                        >
                          <validation-provider
                            v-slot="{ errors }"
                            name="Email"
                            rules="required|email"
                          >
                            <v-text-field
                              v-model="email"
                              :label="$t('loginPage.email')"
                              outlined
                              prepend-inner-icon="mdi-email-outline"
                              :error-messages="errors"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-center">
                        <v-col
                          cols="12"
                          class="pt-1"
                        >
                          <v-btn
                            class="primary whtie--text mr-0  login-btn-new text-uppercase"
                            block
                            large
                            :disabled="invalid"
                            @keyup.enter="submit"
                            @click="resetPass"
                          >
                            <span class="login-btn">{{ $t('forgotPassWordPage.sendResetEmail') }}</span>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </validation-observer>
                  </v-form>
                  <v-row class="d-flex justify-center">
                    <v-col class="mt-4">
                      <div class="signup-text text-center">
                        <button
                          text
                          class="primary--text text-uppercase"
                          @click="loginPage()"
                        >
                          {{ $t('forgotPassWordPage.backToLogin') }}
                        </button>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <!--------------------------------------MOBILE-------------------------------------->
    <!-- <v-row
      v-if="mobileDevice"
      class="d-flex justify-center"
    >
      <v-col
        cols="12"
        sm="8"
        md="6"
        class="px-1"
      >
        <v-card
          class="pa-6"
          elevation="1"
          light
        >
          <v-row class="d-flex justify-center">
            <v-col>
              <v-img
                src="/img/login-logo.svg"
                class="mx-auto"
                width="241"
                height="100"
              ></v-img>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-form
                ref="form"
                @submit.prevent="submit"
              >
                <div
                  class="title-text text-center"
                >
                  <v-row class="mb-2">
                    <v-col>
                      Log in to Your Account
                    </v-col>
                  </v-row>
                </div>
              </v-form>
              <div>
                <v-form
                  ref="form"
                  @submit.prevent="submit"
                >
                  <validation-observer
                    ref="observer"
                    v-slot="{ invalid }"
                  >
                    <v-row class="d-flex justify-center">
                      <v-col
                        cols="12"
                        sm="10"
                        md="9"
                        class="pb-0"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="Email"
                          rules="required|email"
                        >
                          <v-text-field
                            v-model="email"
                            label="Email"
                            outlined
                            append-icon="mdi-email-outline"
                            :error-messages="errors"
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <v-row class="d-flex justify-center">
                      <v-col
                        cols="12"
                        sm="10"
                        md="9"
                        class="pb-0"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          vid="password"
                          name="Password"
                          rules="required"
                        >
                          <v-text-field
                            ref="password"
                            v-model="password"
                            name="password"
                            label="Password"
                            outlined
                            :type="show ?'text':
                              'password'"
                            :append-icon="show ?'mdi-eye-outline':'mdi-eye-off'"
                            :error-messages="errors"
                            @click:append="show=!show"
                          >
                          </v-text-field>
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <v-row class="d-flex justify-center">
                      <v-col
                        cols="12"
                        sm="10"
                        md="9"
                      >
                      </v-col>
                    </v-row>
                    <v-row class="d-flex justify-center">
                      <v-col
                        cols="6"
                        class="d-flex justify-center"
                      >
                        <v-btn
                          class="primary whtie--text mr-0"
                          block
                          large
                          :disabled="invalid"
                          @keyup.enter="submit"
                          @click="resetPass"
                        >
                          <span class="login-btn">Login</span>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </validation-observer>
                </v-form>
                <v-row class="d-flex justify-center">
                  <v-col>
                    <div class="signup-text text-center">
                      <span style="color: #757575">Don't have an account?</span> <button
                        text
                        class="primary--text"
                        @click="registerPage()"
                      >
                        register
                      </button>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
  import { emailRegex } from '@/consts/regex'
  import { localize } from 'vee-validate'
  export default {
    name: 'PagesLogin',
    // components: {
    //   PagesBtn: () => import('./components/Btn'),
    //   // PagesHeading: () => import('./components/Heading'),
    // },
    data: () => ({
      show: false,
      snackbar: false,
      timeout: 0,
      alertmessage: '',
      valid: true,
      repeatPassword: '',
      password: '',
      mobile: '',
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => emailRegex.test(v) || 'E-mail must be valid',
      ],
      passwordrules: [
        v => !!v || 'Passowrd is required',
        v => (v && v.length <= 10) || 'Password must be less than 10 characters',
        v => (v && v.length >= 5) || 'Password must be at least 6 characters',
      ],
      mobilerules: [
        v => !!v || 'Mobile is required',
        v => (v && v.length >= 7) || 'Password must be at least 7 characters',
      ],
      sections: [
        {
          icon: 'mdi-account-check',
          iconColor: 'primary',
          title: 'Create your account',
          text: 'Take full advantage of your Blanket'
        },
        {
          icon: 'mdi-file-document-multiple-outline',
          iconColor: 'secondary',
          title: 'Add policies',
          text: 'Add and review your policies in one place'
        },
        {
          icon: 'mdi-message',
          iconColor: 'green',
          title: 'Contact',
          text: 'Stay in touh with your Blanket agent, and get your questions answered. '
        }
      ],
      socials: [
        {
          href: '#',
          icon: 'mdi-facebook',
          iconColor: '#3B5998'
        },
        {
          href: '#',
          icon: 'mdi-twitter',
          iconColor: '#1DA1F2'
        },
      ]
    }),
    computed: {
      displayError () {
        if (this.$store.getters.getErrors) {
          this.snackbar = true
          return true
        }
        return false
      },
      errorMessage () {
        let message = ''
        message = this.$store.getters.getErrors
        if (message) {
          this.snackbar = true
        }
        return message
      },
      mobileDevice () {
        return this.$vuetify.breakpoint.xsOnly
      },
      tabletDevice () {
        return this.$vuetify.breakpoint.smOnly
      },
      user () {
        return this.$store.getters.user
      },
      error () {
        return this.$store.getters.error
      },
      loading () {
        return this.$store.getters.loading
      },
      loginResponse () {
        if (this.$store.getters.getloginerror) {
          this.snackbar = true
          this.alertmessage = this.$store.getters.getloginerror
        }
        return this.$store.getters.getloginerror
      }
    },
    watch: {
      user (value) {
        if (value !== null && value !== undefined) {
          this.$router.push('/')
        }
      },
    },
    mounted () {
      localize(this.$i18n.locale)
    },
    methods: {
      registerPage () {
        this.$router.push({ path: '/pages/register' })
      },
      loginPage () {
        this.$router.push({ path: '/pages/login' })
      },
      resetPass () {
        this.checking = true
        this.$store.dispatch('sendRecoveryEmail', { email: this.email })
        this.$gtag.event('login', { method: 'PwReset' })
        this.checking = false
      },
      validate () {
        if (this.$refs.form.validate()) {
          this.snackbar = true
        }
      },
      resetErrorResponse () {
        this.$store.dispatch('clearError')
        this.alertmessage = ''
        this.snackbar = false
      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
      onDismissed () {
        this.$store.dispatch('clearError')
      },
      async submit () {
        let complete = await this.$refs.observer.validate()
        if (!complete) {
        } else {
          this.$store.dispatch('signUserIn', { email: this.email, password: this.password })
        }
        let errorstate = this.$store.getters.error
        /*
        setTimeout(() => {
          if (this.$store.getters.user) {
            console.log('WE HAVE A USER TO SEND HOME ', this.$store.getters.user)
            this.snackbar = true
            this.resetForm()
            this.$v.$reset()
            this.$router.push({
              name: 'dashboard/Dashboardv2'
            })
          }
        }, 5000)
        */
        this.checking = false
      }
    }
  }
    </script>

<style lang="scss" scoped>
.signup-text {
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 19px;
line-height: 125%;
text-align: center;
letter-spacing: 0.00892857em;
text-transform: uppercase;
}
.social-icon-login {
  margin-left: 0.7rem;
}
.c-checkbox {
  color: #292F36 !important;
}

.login-btn-new {
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 19px;
line-height: 125%;
color: #292F36 !important;
background: #AAF0C1 !important;
}
.title-text {
  // font-family: 'Roboto';
  // font-style: normal;
  // font-weight: 400;
  // font-size: 24px;
  // line-height: 32px;
  // color: #424242;
font-family: 'Sora';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 32px;
color: #292F36;
}
.light-grey {
  background-color: #FAFAFA
}

// .v-sheet.v-card {
//   border-top: 4px solid #00A1B7 !important;
// }
@media screen and (min-width: 660px) {
  .h-md-50-vw {
    height: 50vw;
  }
}
@media (min-width: 320px) and (max-width: 600px) {
  .v-sheet .v-btn.v-size--large:not(.v-btn--icon):not(.v-btn--fab) {
    padding: 6px 14px !important;
  }
}
.mx-auto{
  margin: 15px;
}
</style>
